import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsyncSelect from "../../hooks/useAsyncSelect";
import AsyncSelect from "react-select/async";
import SolidControl from "../common/form/react-select/SolidControl";
import { buildQueryString } from '../../utils/queryString';
import useApiService from '../../hooks/useApiService';
export default function SiteMultiSelect (props) {
  const {name, value, formik} = props;
  const {setFieldValue} = formik;

  const [site, setSite] = useState(null);

  const racFilter = useMemo(() => {
    return ((formik || {}).values || {}).racFilter;
  }, [formik.values]);

  const rac_ids = useMemo(() => {
    if (!racFilter) return [];
    return Array.isArray(racFilter) ? racFilter : [racFilter];
  }, [racFilter])

  const getRacs = useApiService('')
  const [racs, setRacs] = useState([]);
  useEffect(() => {
    if (rac_ids.length > 0) {
      const queryString = buildQueryString({searchOptions: {
          site_id: rac_ids,
        }})
      getRacs({url: '/auth/racs' + queryString, method: 'get'}).then((response) => {
        if (response && response.data && Array.isArray(response.data.results)) {
          setRacs(response.data.results);
        }
      }).catch(console.error)
    } else {
      setRacs([]);
    }
  }, [racFilter, getRacs, setRacs])

  const sites = useAsyncSelect({
    url: '/auth/sites',
    key: 'site_id',
    searchTerm: 'name',
    filters: {
      exclude: value,
      rac_id: racFilter
    }
  });

  const filterOption = useCallback((candidate) => {
    if (racs.length === 0) return true;
    return racs.findIndex((rac) => {
      return rac.abbreviation === candidate.data.rac
    }) !== -1;
  }, [racs, rac_ids]);

  const handleChange = useCallback((site) => {
    setSite(site);
    if (!Array.isArray(site)) site = [site];
    const selectedSiteIds = (site || []).filter(site => !!site).map(site => site.site_id);
    setFieldValue(name, selectedSiteIds);
  }, [setSite, name, setFieldValue]);

  return <AsyncSelect
    name={name}
    placeholder={'Choose a site.'}
    //isMulti
    //className={'w-100' + (hasError ? ` is-invalid ${classes.invalid}` : '')}
    loadOptions={sites.loadOptions}
    getOptionLabel={option => option.common_name || option.legal_name}
    getOptionValue={option => option.site_id}
    loadValue={sites.loadValue}
    defaultOptions={sites.options}
    onChange={handleChange}
    isClearable={true}
    value={site}
    menuPlacement={'auto'}
    components={{Control: SolidControl}}
    filterOption={filterOption}
  />
}
