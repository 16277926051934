import React, { useMemo, useState, useEffect, useCallback } from "react";

import { Button } from 'react-bootstrap';

import { useFormikContext, FieldArray } from "formik";

import UserRoleInput from "./UserRoleInput";
import { getRefs } from "../../utils/helpers";
import UserService from "../../services/user";
import { buildQueryString } from "../../utils/queryString";
import { useHasRoles } from "../../utils/customHooks";
import { RoleEnum } from "../../config/roles";
import Typography from "@material-ui/core/Typography";
import useApiService from "../../hooks/useApiService";
import useAxiosResponse from "../../hooks/useAxiosResponse";

const defaultSite = {
  site_id: '0',
  common_name: "ALL"
}

const userRoleSiteIdSelector = (index = 0) => `userRoles[${index}].site_id`;
const siteReduce = (accumulatedSites, currentSite) => {
  let foundSite = false;
  accumulatedSites = accumulatedSites.map((prevSite) => {
    if (!currentSite.site_id) return accumulatedSites;
    if (`${prevSite.site_id}` === `${currentSite.site_id}`) {
      foundSite = true;
      return currentSite;
    } else {
      return prevSite;
    }
  });
  if (foundSite) return accumulatedSites;
  return accumulatedSites.concat(currentSite);
}

const UserRolesInput = () => {
  const { values } = useFormikContext();
  const hasAllSites = useHasRoles([RoleEnum.RoleAdmin], [0]);

  const selectedSites = useMemo(() => {
    return getRefs(values, userRoleSiteIdSelector());
  }, [values]);

  const [sites, setSites] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);

  const updateSites = useCallback((newSites) => {
    if (!Array.isArray(newSites)) newSites = [newSites];
    setSites((prevState) => {
      return prevState.reduce(siteReduce, newSites);
    });
  }, [setSites]);

  const fetchTitles = useApiService({
    url: '/auth/title',
    key: 'user_title_id',
  });
  const [{response: titles}] = useAxiosResponse(fetchTitles);

  const searchSites = useCallback((inputValue, callBack) => {
    const count = 100;
    const queryString = buildQueryString({
      orderType: 'asc',
      orderBy: 'site_id',
      page: 0,
      rowsPerPage: count,
      searchOptions: {
        name: inputValue,
        excludeSites: selectedSites || []
      }
    });
    UserService.getOptionSites(queryString).then(ret => {
      let {results, total_rows} = ret.data;
      if (hasAllSites) results = [defaultSite].concat(results);
      const messages = [];
      if (total_rows > count) {
        messages.push({label: `And ${total_rows - count} more.`, isDisabled: true})
      }
      const options = results.map((site) => ({label: site.common_name || site.legal_name, value: site.site_id}));
      if (typeof callBack === "function") callBack(options.concat(messages));
      updateSites(results);
    }).catch(e => {
      console.log(e);
    });
  }, [selectedSites, updateSites, hasAllSites]);

  const loadSite = useCallback((id) => {
    let found = sites.find((site) => `${site.site_id}` === `${id}`);
    if (found) return Promise.resolve({label: found.common_name || found.legal_name, value: id});
    return UserService.getOptionSite(id).then((response) => {
      const {data} = response;
      updateSites([data]);
      return {label: data.common_name || data.legal_name, value: id};
    }).catch ((e) =>  {
      console.log(e);
    });
  }, [sites, updateSites]);

  useEffect(() => {
    searchSites(undefined, undefined);
  }, [searchSites]);

  useEffect(() => {
    const options = sites
      .map(({site_id, common_name, legal_name}) => {
        return {label: common_name || legal_name, value: `${site_id}`};
      });
    setSiteOptions(options);
  }, [sites, setSiteOptions]);

  if (sites.length === 0) return <>loading</>;

  return (
    <FieldArray
      name="userRoles"
      render={({ remove, push }) => (
        <>
          <Typography variant="h6">Roles</Typography>
          {values.userRoles.map((userRole, index) => (
            <UserRoleInput
              remove={remove}
              key={index}
              index={index}
              userRole={userRole}
              name={userRoleSiteIdSelector(index)}
              sites={siteOptions}
              titles={titles}
              loadSite={loadSite}
              loadSites={searchSites}
              selectedSites={selectedSites}
            />
          ))}
          <div className="col-lg-12 mt-6">
            <Button
              //variant="contained"
              //color="secondary"
              //startIcon={<AddIcon />}
              onClick={() => {
                push({
                  site_id: "",
                  dashboard_user: false,
                  stroke_user: false,
                  cardiac_user: false,
                  upload_user: false,
                  user_admin: false,
                  site_admin: false,
                  role_admin: false,
                  developer: false
                });
              }}
            >
              Add roles
            </Button>
          </div>
        </>
      )}
    />
  );
};

export default UserRolesInput;
